import React from 'react';
import './App.css';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

const LOCALSTORAGE_KEY = 'hpclPassword';
const API_HOST = 'https://haoxingpeterchecklist.com:10005/';

function getNewId(): string {
  const unixTimestamp = Math.floor(Date.now() * 1e-3);
  let random = '';
  for (let i = 0; i < 8; i++)
    random += Math.floor(Math.random() * 16).toString(16);
  return `${unixTimestamp}-${random}`;
}

function getPassword() {
  const item = localStorage.getItem(LOCALSTORAGE_KEY);
  if (item !== null)
    return item;
  let password = null;
  while (password === null)
    password = window.prompt('What is the password?');
  localStorage.setItem(LOCALSTORAGE_KEY, password);
  return password;
}

async function apiCall(kind: string, payload: any): Promise<any> {
  const response = await window.fetch(API_HOST, {
    method: 'POST',
    headers: { 'Content-Type' : 'application/json'},
    body: JSON.stringify({
      password: getPassword(),
      kind,
      payload,
    }),
  });
  if (response.status === 403) {
    localStorage.removeItem(LOCALSTORAGE_KEY);
    alert('Bad password!');
  }
  return await response.json();
}

interface IEditableTextProps {
  text: string;
  onChange: (newText: string) => void;
}

class EditableText extends React.PureComponent<IEditableTextProps, {
  isEditing: boolean;
  text: string;
}> {
  constructor(props: IEditableTextProps) {
    super(props);
    this.state = {
      isEditing: false,
      text: this.props.text,
    }
  }

  render() {
    if (this.state.isEditing) {
      return <>
        <input value={this.state.text} onChange={(event) => this.setState({ text: event.target.value })} />
        <button style={{ marginLeft: 5 }} onClick={() => {
          this.setState({ isEditing: false });
          this.props.onChange(this.state.text);
        }}>Save</button>
        <button style={{ marginLeft: 5 }} onClick={() => this.setState({ isEditing: false, text: this.props.text })}>Cancel</button>
      </>;
    }

    return <>
      {this.state.text}
      <span
        className='clickable'
        style={{ fontSize: '120%', marginLeft: 20 }}
        onClick={() => this.setState({ isEditing: true })}
      >
        ✎
      </span>
    </>;
  }
}

export class App extends React.PureComponent<{}, {
  checklist: any[];
  editMode: boolean;
}> {
  constructor(props: {}) {
    super(props);
    this.state = {
      checklist: [],
      editMode: false,
    };
    this.getChecklist();
    setInterval(() => this.getChecklist(), 2500);
  }

  async getChecklist() {
    const payload = await apiCall('getChecklist', null);
    this.setState({ checklist: payload.checklist });
  }

  async toggleItem(index: number) {
    const checklist = [...this.state.checklist];
    checklist[index].set = !checklist[index].set;
    this.setState({ checklist });
    apiCall('setChecklist', checklist);
  }

  async deleteItem(index: number) {
    const checklist = [...this.state.checklist];
    checklist.splice(index, 1);
    this.setState({ checklist });
    apiCall('setChecklist', checklist);
  }

  async setItemText(index: number, text: string) {
    const checklist = [...this.state.checklist];
    checklist[index].name = text;
    this.setState({ checklist });
    apiCall('setChecklist', checklist);
  }

  async newItem() {
    const checklist = [...this.state.checklist];
    checklist.push({ name: 'New item', set: false, id: getNewId() });
    this.setState({ checklist });
    apiCall('setChecklist', checklist);
  }

  onDragEnd = (result: DropResult) => {
    if (!result.destination)
      return;
    const checklist = [...this.state.checklist];
    const [removed] = checklist.splice(result.source.index, 1);
    checklist.splice(result.destination.index, 0, removed);
    this.setState({ checklist });
    apiCall('setChecklist', checklist);
  }

  render() {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
      }}>
        <h1>浩星-Peter checklist</h1>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="tasks">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  border: '1px solid black',
                  borderRadius: 5,
                  backgroundColor: snapshot.isDraggingOver ? '#448' : '#446',
                  transition: 'background-color 0.2s',
                  padding: 15,
                }}
              >
                {this.state.checklist.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          padding: 10,
                          ...provided.draggableProps.style,
                        }}
                      >
                        <div style={{
                          border: '1px solid black',
                          borderRadius: 5,
                          backgroundColor: snapshot.isDragging ? '#226' : (item.set ? '#224' : '#335'),
                          padding: 10,
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                          {this.state.editMode && <>
                            <div {...provided.dragHandleProps} style={{ marginRight: 10, fontSize: '150%' }}>
                              ⇅
                            </div>
                            <div
                              className='clickable'
                              style={{ marginRight: 10, fontSize: '120%' }}
                              onClick={() => {
                                if (window.confirm('Permanently delete: ' + item.name + '?'))
                                  this.deleteItem(index);
                              }}
                            >
                              🗑
                            </div>
                          </>}
                          <input
                            type='checkbox'
                            style={{ transform: 'scale(1.5)', marginRight: 15 }}
                            checked={item.set}
                            onChange={() => this.toggleItem(index)}
                          />

                          {this.state.editMode ?
                            <EditableText text={item.name} onChange={(newText: string) => {
                              this.setItemText(index, newText);
                            }} />
                          : item.name}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{ margin: 20 }}>
          {this.state.editMode ? <>
            <button className='clickable' onClick={() => this.newItem()}>
              + New item
            </button>
            <button style={{ marginLeft: 20 }} className='clickable' onClick={() => this.setState({ editMode: false })}>
              Leave edit mode
            </button>
          </>
          :
            <button style={{ marginLeft: 20 }} className='clickable' onClick={() => this.setState({ editMode: true })}>
              Edit list
            </button>
          }
        </div>
      </div>
    );
  }
}

export default App;
